"use strict";

// wow-animate========================================================================================================================================================
new WOW().init();
$(document).ready(function () {
  // header-burger========================================================================================================================================================
  $('.header__burger').on('click', function () {
    if ($(this).hasClass('active')) {
      $(this).toggleClass("active");
      $('body').css('overflow', 'auto');
      $('header').addClass('header_right'); //dark bg//

      $('body').removeClass('open');
    } else {
      $('header').removeClass('header_right');
      $(this).toggleClass("active");
      $('body').css('overflow', 'hidden'); //dark bg//

      $('body').addClass('open');
    }
  }); // header-scroll========================================================================================================================================================

  var scrollChange = 50;

  function headerScroll(params) {
    var scroll = $(window).scrollTop();

    if (scroll >= scrollChange) {
      $('.header').addClass('header_scroll');
    } else {
      $('.header').removeClass('header_scroll');
    }
  }

  $(document).on('scroll', function () {
    headerScroll();
  });
  headerScroll(); // disable SLIDER========================================================================================================================================================
  // breakpoint where swiper will be destroyed

  var breakpoint = window.matchMedia('(max-width:991px)');
  var verticalSwiper;

  var breakpointChecker = function breakpointChecker() {
    if (breakpoint.matches === true) {
      if (verticalSwiper !== undefined) verticalSwiper.destroy(true, true);
      return;
    } else if (breakpoint.matches === false) {
      return enableSwiper();
    }
  }; // Vertical Swiper========================================================================================================================================================


  var enableSwiper = function enableSwiper() {
    if ($(".main-swiper-vertical").length > 0) {
      verticalSwiper = new Swiper(".main-swiper-vertical", {
        speed: 800,
        direction: "vertical",
        slidesPerView: "auto",
        spaceBetween: 0,
        mousewheel: {
          invert: false
        }
      });
      $('.mouse__home').on('click', function () {
        verticalSwiper.slideTo(1, 800);
      });
      verticalSwiper.on('slideChange', function () {
        if (verticalSwiper.realIndex > 0) {
          $('header').addClass('header_scroll');
        } else {
          $('header').removeClass('header_scroll');
        }
      });
    }
  };

  breakpoint.addListener(breakpointChecker);
  breakpointChecker(); // play video========================================================================================================================================================
  ///// play-youtube /////

  var video_wrapper = $('.youtube-video-place');

  if (video_wrapper.length) {
    $('.play_btn').on('click', function () {
      video_wrapper.html('<iframe allowfullscreen width="582" height="328" frameborder="0" class="embed-responsive-item" src="' + video_wrapper.data('yt-url') + '"></iframe>');
    });
  } // swiper-projects========================================================================================================================================================


  var projectImagesSlider = new Swiper('.swiper-projects', {
    slidesPerView: 3,
    speed: 1000,
    spaceBetween: 30,
    loop: true,
    autoplay: true,
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      450: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 40
      }
    }
  }); // swiper-urban-gardens========================================================================================================================================================

  var UrbanGardenSlider = new Swiper('.swiper-urban-gardens', {
    slidesPerView: 1,
    speed: 800,
    spaceBetween: 20,
    loop: true,
    autoplay: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  }); // Scroll animate========================================================================================================================================================

  $('.argoponics__list a').click(function () {
    $('html, body').animate({
      scrollTop: $($(this).attr('href')).offset().top
    }, 500);
    return false;
  });
  $('.mouse__pages a').click(function () {
    $('html, body').animate({
      scrollTop: $($(this).attr('href')).offset().top
    }, 300);
    return false;
  }); // slide_3========================================================================================================================================================

  $('.home-argo__col').on('click', function () {
    if (breakpoint.matches === true) {
      $('.argo-hover__text').removeClass('show');
      $(this).children().addClass('show');
    } else if (breakpoint.matches === false) {
      $('.argo-hover__text').removeClass('show');
    }
  }); // GET YEAR ========================================================================================================================================================

  if (document.querySelectorAll('.footer__copyright').length > 0) {
    var yearSpan = document.querySelector('.year');
    var date = new Date();
    var yearFull = date.getFullYear();
    yearSpan.innerHTML = yearFull;
  } // Animation========================================================================================================================================================
  //Animation h1 hero section


  gsap.registerPlugin(ScrollTrigger);
  var text = document.querySelectorAll('.text');
  text.forEach(function (elem) {
    var splitText = function splitText(el) {
      el.innerHTML = el.textContent.replace(/(\S*)/g, function (m) {
        return "<span class=\"word\">" + m.replace(/(-|#|@)?\S(-|#|@)?/g, "<span class='letter'>$&</span>") + "</span>";
      });
      return el;
    };

    var split = splitText(elem);
  });
  gsap.from('h1.hero__title span.letter', {
    opacity: 0,
    y: 30,
    duration: 0.5,
    stagger: 0.03
  });
  gsap.from('h1.pages__title span.letter', {
    opacity: 0,
    y: 30,
    duration: 0.5,
    stagger: 0.1
  });
}); // Style map========================================================================================================================================================

var stylesLocation = [{
  "elementType": "geometry",
  "stylers": [{
    "color": "#f5f5f5"
  }]
}, {
  "elementType": "labels.icon",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#616161"
  }]
}, {
  "elementType": "labels.text.stroke",
  "stylers": [{
    "color": "#f5f5f5"
  }]
}, {
  "featureType": "administrative.land_parcel",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#bdbdbd"
  }]
}, {
  "featureType": "poi",
  "elementType": "geometry",
  "stylers": [{
    "color": "#eeeeee"
  }]
}, {
  "featureType": "poi",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#757575"
  }]
}, {
  "featureType": "poi.park",
  "elementType": "geometry",
  "stylers": [{
    "color": "#e5e5e5"
  }]
}, {
  "featureType": "poi.park",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#9e9e9e"
  }]
}, {
  "featureType": "road",
  "elementType": "geometry",
  "stylers": [{
    "color": "#ffffff"
  }]
}, {
  "featureType": "road.arterial",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#757575"
  }]
}, {
  "featureType": "road.highway",
  "elementType": "geometry",
  "stylers": [{
    "color": "#dadada"
  }]
}, {
  "featureType": "road.highway",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#616161"
  }]
}, {
  "featureType": "road.local",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#9e9e9e"
  }]
}, {
  "featureType": "transit.line",
  "elementType": "geometry",
  "stylers": [{
    "color": "#e5e5e5"
  }]
}, {
  "featureType": "transit.station",
  "elementType": "geometry",
  "stylers": [{
    "color": "#eeeeee"
  }]
}, {
  "featureType": "water",
  "elementType": "geometry",
  "stylers": [{
    "color": "#c9c9c9"
  }]
}, {
  "featureType": "water",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#9e9e9e"
  }]
}];